/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mxl5qd1r2i.execute-api.eu-west-2.amazonaws.com/dentons",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://v3sjzbivr2.execute-api.eu-west-2.amazonaws.com/dentons",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://7v1pn2ieai.execute-api.eu-west-2.amazonaws.com/dentons",
            "region": "eu-west-2"
        },
        {
            "name": "reminderEmail",
            "endpoint": "https://dne5esj8yh.execute-api.eu-west-2.amazonaws.com/dentons",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://dtqtm7u4l5bmvi6g2rda6c2bny.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:047d531b-d21c-4a08-8192-0d34c95c0dfd",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_DoXVFmITr",
    "aws_user_pools_web_client_id": "ea3cjoadinagp52jv9bdb3i53",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c190225-dentons",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
