import * as React from 'react';
import { Box, Typography, CircularProgress, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper } from '@mui/material';
import { useGetList, Form, useRefresh, CreateButton, Button, SelectInput, useGetOne, ReferenceInput, downloadCSV, useStore, useNotify, usePermissions } from 'react-admin';
import { API, Auth } from 'aws-amplify';
import { useWatch } from 'react-hook-form';
import jsonExport from 'jsonexport/dist';
import { TraineeExportButton } from './TraineeExportButton';
import { is_preference_white_text, get_color_code, get_num_of_preferences, wordy_ordinal } from './variable_preferences';


export const RotationDataField = ({ currentRotation }) => {
    var chips = []
    for (let priority = 1; priority <= process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'
        chips.push(<Chip sx={{ backgroundColor: get_color_code(priority), color: text_color, m: 1 }} label={get_num_of_preferences(priority, currentRotation)} />)
    }
    chips.push(<Chip sx={{ backgroundColor: '#11111f', color: "white", m: 1 }} label={get_num_of_preferences(0, currentRotation)} />)

    return <Typography>
        {chips}
    </Typography>
}

const exporter = (records, fetchRelatedRecords, dataProvider, params) => {
    const {rotationID} = params
    // console.log(records);
    Promise.all(records.sort((a,b)=>a.name.localeCompare(b.name)).map(async trainee => {
        const { id, Preferences, cohort, cohortID, Seats, PublishedSeats, manualOverride,siteID,manualOverrideID, createdAt, type, updatedAt, ownerID, litigationSeatCompleted, bankingSeatCompleted, desiredGraduatingSeatID, desiredGraduatingSeat, traineeDiary, __typename, hrComments, firstSeatSupervisorHR, secondSeatSupervisorHR, thirdSeatSupervisorHR, fourthSeatSupervisorHR, ...traineeForExport } = trainee; // omit backlinks and author
        const preferences = Array(3);
        for (var preference in Preferences.items) {
            if (Preferences.items[preference].rotationID == rotationID) {
                preferences[Preferences.items[preference].priority - 1] = await dataProvider.getOne('departments', { id: Preferences.items[preference].departmentID })
            }
        }
        const rotationsList = await dataProvider.getList("rotations",{filter: {}, meta: {}, pagination:{page: 1}, sort:{field: "startDate"}})
        var previousRotation = rotationsList.data[0].id;
        var mostRecentStartDate = rotationsList.data[0]?.startDate;
        for (var rotationIndex in rotationsList.data) {
            if (rotationsList.data[rotationIndex]?.startDate >= mostRecentStartDate && rotationsList.data[rotationIndex]?.id != rotationID) {
                mostRecentStartDate = rotationsList.data[rotationIndex]?.startDate
                previousRotation = rotationsList.data[rotationIndex]?.id
            }
        }
        console.warn(previousRotation, PublishedSeats)

        let previousSeat;
        for (var seat in PublishedSeats.items) {
            if(PublishedSeats.items[seat].rotationID == previousRotation) {
                previousSeat = await dataProvider.getOne('departments', { id: PublishedSeats.items[seat].departmentID });
            }
        }
        let assignedSeat;
        for (var seat in Seats.items) {
            if (Seats.items[seat].rotationID == rotationID) {
                assignedSeat = await dataProvider.getOne('departments', { id: Seats.items[seat].departmentID });
            }
        }
        var preferencesDict = {}
        for (var preferenceIndex in preferences) {
            const preferenceString = wordy_ordinal(parseInt(preferenceIndex) + 1) + " Preference"
            preferencesDict[preferenceString] = preferences[preferenceIndex]?.data.name

        }
        const site = await dataProvider.getOne('sites', { id: siteID })
        return {
            ...traineeForExport,
            ...preferencesDict,
            'Site': site?.data.name,
            'Assigned Seat': assignedSeat?.data.name,
            'Previous Seat': previousSeat?.data.name,
            'Cohort Start Date': cohort.startDate,
            'Manual Override': manualOverride?.name,
            'Early Careers Development Team Comments': hrComments?.replace(/<[^>]*>/g, '')?.replace(/\\par[d]?/g, "").replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim(),
            // 'Trainee Comments': traineeDiary?.replace(/<[^>]*>/g, '')?.replace(/\\par[d]?/g, "").replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "").trim(),
            '1st Seat Supervisor': firstSeatSupervisorHR,
            '2nd Seat Supervisor': secondSeatSupervisorHR,
            '3rd Seat Supervisor': thirdSeatSupervisorHR,
            '4th Seat Supervisor': fourthSeatSupervisorHR
        };
    })).then(traineesForExport => {
        jsonExport(traineesForExport, {
            headers: ['name', 'email', 'Cohort Start Date', 'Site', 'Manual Override', 'Assigned Seat', 'Previous Seat'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'trainees'); // download as 'posts.csv` file
        });
    });
};


export const RotationFilterForm = ({ rotationID, siteID, setSiteID, currentCohorts, setCurrentCohorts, isLoading }) => {

    const refresh = useRefresh();
    const notify = useNotify();
    const [assignSeatsLoading, setAssignSeatsLoading] = React.useState(false)
    const [publishSeatsLoading, setPublishSeatsLoading] = React.useState(false)
    const rotationsList = useGetList('rotations', { filter: { rotationsByTypeAndStartDateAdmin: { type: "Rotation" } } });
    var rotations = rotationsList.data
    rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
    const currentRotation = rotations?.at(-1)
    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            console.log(values.cohortsIndex, values.siteID)

            if (values.cohortsIndex != "") {
                var currentCohortsTemp = []
                for (var rotationIndex in rotationsList.data) {
                    if (rotations[rotationIndex].cohortsIndex == values.cohortsIndex){
                        currentCohortsTemp.push(rotations[rotationIndex])
                    }
                }
                if (currentCohortsTemp.length == 0){
                    setCurrentCohorts(rotationsList.data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible)))
                }else {
                setCurrentCohorts(currentCohortsTemp);
                }
            } 
            console.log(values.cohortsIndex, values.siteID)
            if (values.siteID == null) {
                setSiteID("")
            }
            if (values.siteID != siteID) {
                setSiteID(values.siteID)
            } 
            console.log(values.cohortsIndex, values.siteID)
        }
    };

    const validateStartDate = (values) => {
        const errors = {};
        return errors
    }

    const assignSeats = () => {
        const apiName = 'assignSeats';
        const path = '/assignseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': rotationID
                }
            };
            setAssignSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setAssignSeatsLoading(false); refresh() }).catch(error => { setAssignSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const publishSeats = () => {
        const apiName = 'publishSeats';
        const path = '/publishseats';

        let jwt;
        Auth.currentSession().then(data => {
            // console.log(data)
            // console.log(data.idToken)
            const myInit = {
                headers: {
                    Authorization: data.idToken.jwtToken
                }, // OPTIONAL
                response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
                queryStringParameters: {
                    'rotationID': rotationID
                }
            };
            setPublishSeatsLoading(true);
            API.get(apiName, path, myInit).then(() => { setPublishSeatsLoading(false); refresh() }).catch(error => { setPublishSeatsLoading(false); notify(error.response.data, { type: 'error' }); })
        })
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { permissions } = usePermissions();

    var currentCohortID = currentCohorts[0]?.cohortsIndex
    if (currentCohorts.length > 2) {
        currentCohortID = ""
    }
    return (
        <>
            <Form onSubmit={onSubmit} validate={validateStartDate} defaultValues={{ siteID: siteID, cohortsIndex: currentCohortID }}>
                <Box display="flex" flexDirection="column" justifyContent='center' mb={1} sx={{ flexGrow: 1, position: "fixed", 'z-index': 3 }}>
                    <Paper elevation={3} mt={1}>
                        {isLoading ? null : <RotationDataField currentRotation={currentRotation} />}
                    </Paper>
                </Box>



                <Box display="flex" justifyContent='center' alignItems="flex-end" mb={1}>

                    <Box display="flex" alignItems="stretch" alignContent='flex-end' mb={1} mt={1} width={"100%"} flexDirection={'row-reverse'} >
                        
                        <Box component="span" mr={2} >
                            {isLoading ? null : <TraineeExportButton exporter={exporter} params={{rotationID}} size='large' variant="outlined" color="primary" resource="trainees" />}
                        </Box>
                        {permissions?.includes("SuperAdmins") &&
                            <Box component="span" mr={2}>
                                <CreateButton label="Add Trainee" size='large' variant="outlined" color="primary" resource="trainees" />
                            </Box>}
                        <Box component="span" mr={2}>
                            <Button size='large' variant="outlined" color="primary" onClick={assignSeats} disabled={currentRotation?.published || assignSeatsLoading}>
                                {assignSeatsLoading ? <CircularProgress size="26px" /> : "Assign seats"}
                            </Button>
                        </Box>

                        <Box component="span" mr={2} >
                            <Button size='large' variant="outlined" color="primary" onClick={handleClickOpen} disabled={currentRotation?.published || assignSeatsLoading}>
                                {publishSeatsLoading ? <CircularProgress size="26px" /> : "Publish seats"}
                            </Button>
                        </Box>
                        <Box component="span" mr={2} mb={3.5}>
                                <Button size='large' variant="outlined" color="primary" type="submit">
                                    Filter
                                </Button>
                            </Box>
                        <Box display="flex" alignItems="flex-end" mb={1}   >
                            <Box component="span" mr={2} >
                            <SelectInput source="cohortsIndex" label="Cohort" choices={[
                                    { id: '0', name: 'First Seat' },
                                    { id: '1', name: 'Second Seat' },
                                    { id: '2', name: 'Third Seat' },
                                    { id: '3', name: 'Fourth Seat' },
                                    { id: '8', name: 'Fifth Year' },
                                    { id: '10', name: 'Sixth Year' }
                                ]} />
                            </Box>
                            <Box component="span" mr={2} >
                                <ReferenceInput source="siteID" reference='sites'  >
                                    <SelectInput label="Site" sx={{ width: 200 }} />
                                </ReferenceInput>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
            </Form>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Publish seats"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Publishing seats will freeze the currently assigned seats, make them visible to the trainees and email them notifying them of their seat for the next rotation.
                        <br /><br />It will also update the seat supervisors on the trainee views, so make sure you have finished entering any of these you wish to before publishing.
                        <br /><br />This process is <b>irreversible</b>. Are you sure you want to continue?
                    </DialogContentText>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={() => { handleClose(); publishSeats(); }} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
};